path {
  pointer-events: all;
}
path:hover {
  opacity: 0.5;
  cursor: pointer;
}
.fc-daygrid-event-harness {
  cursor: pointer;
}
